import React, { useCallback } from 'react';

import { ChevronRightFill, CornerDownRightOutline } from '@lyearn/icons';
import { ActionMenu, ActionMenuItemType } from '@lyearn/molecules';
import { cn } from '@lyearn/style';
import { Avatar, Stack, Typography } from '@lyearn/ui';

import { BREADCRUMBS_RESOURCE_TYPE_CONFIG } from '../../consts';
import { Breadcrumb, useBreadcrumbContext } from '../../context';
const ICON_CLASS_NAME = {
  _16px: 'h-16 w-16',
  _22px: 'h-22 w-22',
};
const BreadcrumbItem = React.forwardRef(
  (
    {
      breadcrumb,
      isLast,
      onClick,
      useCompact,
    }: {
      breadcrumb: Breadcrumb;
      isLast: boolean;
      onClick?: () => void;
      useCompact?: boolean;
    },
    ref: React.Ref<HTMLDivElement> | undefined,
  ) => {
    const config = breadcrumb?.module?.type
      ? BREADCRUMBS_RESOURCE_TYPE_CONFIG[breadcrumb.module.type]
      : undefined;
    const Icon = config ? config.Icon : undefined;

    const profilePicture =
      breadcrumb?.module?.type === 'PROFILE' ? breadcrumb?.module?.meta?.profilePicture : undefined;

    if (isLast) {
      return (
        <Stack
          ref={ref}
          alignItems="center"
          className={cn('h-32', useCompact ? '' : 'px-10')}
          gapX={2}>
          {Icon ? <Icon className="h-16 w-16" fillColorToken="icon-placeholder" /> : null}
          {profilePicture ? <Avatar size="x-small" src={profilePicture} /> : null}
          <Typography color="text-text-placeholder" variant="body-short-02">
            {breadcrumb.pageTitle}
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack
        ref={ref}
        alignItems="center"
        className={cn('h-32 cursor-pointer', useCompact ? '' : 'px-10')}
        gapX={useCompact ? 0 : 6}
        role="button"
        onClick={onClick}>
        {Icon ? <Icon className="h-16 w-16" fillColorToken="icon-secondary" /> : null}
        {profilePicture ? <Avatar size="x-small" src={profilePicture} /> : null}
        <Typography color="text-text-secondary" variant="body-short-02">
          {breadcrumb.pageTitle}
        </Typography>
      </Stack>
    );
  },
);

const BreadcrumbsLayout = ({
  breadcrumbs,
  className,
  useCompact,
}: {
  breadcrumbs: Breadcrumb[];
  className?: string;
  useCompact?: boolean;
}) => {
  const { onBreadcrumbClick } = useBreadcrumbContext();
  const iconClassName = useCompact ? ICON_CLASS_NAME._22px : ICON_CLASS_NAME._16px;
  const isBreadcrumbsMoreThanThree = breadcrumbs.length > 3;

  const popoverBreadcrumbs = breadcrumbs.slice(1, breadcrumbs.length - 2);
  const last2Breadcrumbs = breadcrumbs.slice(breadcrumbs.length - 2);
  const actionMenuItems = popoverBreadcrumbs.map((breadcrumb, i) => {
    return {
      id: breadcrumb.id,
      label: breadcrumb.pageTitle,
      type: 'item',
      StartIcon: i !== 0 ? CornerDownRightOutline : undefined,
    };
  }, []) as ActionMenuItemType[];

  const onAction = useCallback(
    (path: string) => {
      const clickedBreadcrumb = breadcrumbs.find((b) => b.id === path);
      if (clickedBreadcrumb) {
        onBreadcrumbClick(clickedBreadcrumb);
      }
    },
    [breadcrumbs, onBreadcrumbClick],
  );

  if (!isBreadcrumbsMoreThanThree) {
    return (
      <Stack
        alignItems="center"
        className={cn(className, 'border-t border-border bg-bg-primary px-12 py-4')}>
        {breadcrumbs.map((breadcrumb, index, arr) => (
          <Stack key={`${breadcrumb.id}-more-than-three`} alignItems="center">
            {index !== 0 ? (
              <ChevronRightFill className={iconClassName} fillColorToken="icon-secondary" />
            ) : null}
            <BreadcrumbItem
              breadcrumb={breadcrumb}
              isLast={index === arr.length - 1}
              useCompact={useCompact}
              onClick={() => onBreadcrumbClick(breadcrumb)}
            />
          </Stack>
        ))}
      </Stack>
    );
  }

  return (
    <Stack
      alignItems="center"
      className={cn(className, 'border-t border-border bg-bg-primary px-12 py-4')}>
      <BreadcrumbItem
        breadcrumb={breadcrumbs[0]}
        isLast={false}
        useCompact={useCompact}
        onClick={() => onBreadcrumbClick(breadcrumbs[0])}
      />
      <ChevronRightFill className={iconClassName} fillColorToken="icon-secondary" />
      <ActionMenu
        items={actionMenuItems}
        overrides={{
          PopoverContent: {
            position: 'bottom',
          },
        }}
        onAction={onAction}>
        <BreadcrumbItem
          breadcrumb={{
            pageTitle: '...',
            path: '',
            id: '...',
          }}
          isLast={false}
          useCompact={useCompact}
        />
      </ActionMenu>
      {last2Breadcrumbs.map((breadcrumb, index, arr) => (
        <Stack key={`${breadcrumb.id}-less-than-three`} alignItems="center">
          <ChevronRightFill className={iconClassName} fillColorToken="icon-secondary" />
          <BreadcrumbItem
            breadcrumb={breadcrumb}
            isLast={index === arr.length - 1}
            useCompact={useCompact}
            onClick={() => onBreadcrumbClick(breadcrumb)}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const Breadcrumbs = ({ className, useCompact }: { className?: string; useCompact?: boolean }) => {
  const { breadcrumbs } = useBreadcrumbContext();

  if (breadcrumbs.length < 2) return null;

  return (
    <BreadcrumbsLayout breadcrumbs={breadcrumbs} className={className} useCompact={useCompact} />
  );
};

export default Breadcrumbs;
