import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import Platform from '@lyearn/core/utils/Platform';
import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Stack, Typography } from '@lyearn/ui';
import { useFetchConfig } from '@/components/ConfigsProvider';
import { NavConfigFragment } from '@/components/ConfigsProvider/graphql/fragment/generated/NavConfig';
import { MobileNavigationType } from '@/components/Header/components/NavigationTabs/types';
import { adaptMobileNavigationTabs } from '@/components/Header/components/NavigationTabs/utils';
import { Image } from '@/components/Image';
import { Link } from '@/components/Link';
import { useFeatureFlags } from '@/helper/FeatureFlags';
import useNavigation from '@/hooks/useNavigation';
import { svgSizeStyles } from '@/utils/styles';

interface BottomNavigationTabsProps {
  bottomNavFixed?: boolean;
  previewNavConfig?: NavConfigFragment;
  styles?: React.CSSProperties;
}

const isNative = Platform.OS !== 'web';

/**
 * https://github.com/straxico/use-detect-keyboard-open
 * @param minKeyboardHeight
 * @param defaultValue
 */
const useDetectKeyboardOpen = (minKeyboardHeight = 300, defaultValue = false) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(defaultValue);

  React.useEffect(() => {
    if (isNative) {
      return;
    }

    const listener = () => {
      const newState =
        window.screen?.height - minKeyboardHeight > (window.visualViewport?.height || 0);
      if (isKeyboardOpen != newState) {
        setIsKeyboardOpen(newState);
      }
    };
    window.visualViewport?.addEventListener('resize', listener);

    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  }, [isKeyboardOpen, minKeyboardHeight]);

  return isKeyboardOpen;
};

const BottomNavigationTabs: React.FC<BottomNavigationTabsProps> = (props) => {
  const { bottomNavFixed, previewNavConfig, styles } = props;
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { isMobile } = useScreen();
  const { navConfig } = useFetchConfig(['navConfig']);
  const featureFlags = useFeatureFlags();

  const adaptedNavConfig = previewNavConfig || navConfig;

  const tabs = React.useMemo(
    () => adaptMobileNavigationTabs({ t, navConfig: adaptedNavConfig, featureFlags }),
    [t, adaptedNavConfig, featureFlags],
  );

  const keyboardOpen = useDetectKeyboardOpen();

  //only show these tabs in mobile web and keyboard is not open
  if (!tabs || !isMobile || isNative || keyboardOpen) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      aria-label={t('Common:PrimaryNav')}
      className={cn(
        'bottom-0 z-20 w-full bg-bg-primary pb-8 shadow-05',
        bottomNavFixed ? 'fixed' : 'sticky',
      )}
      component="nav"
      id="bottom-navigation"
      justifyContent="around"
      style={styles}>
      {tabs
        .filter((t): t is MobileNavigationType => !!t)
        .map((tab, index) => {
          const label =
            tab?.id === 'customLink' || tab?.id === undefined
              ? tab?.label
              : t(`Common:NavConfig.${tab?.id}`);

          const isSelected = navigation.asPath.includes(tab.value);

          const propsObj = JSON.parse(tab?.props || '{}');
          const isHiddenOnMobile = Boolean(propsObj?.hiddenFromMobileView);

          if (isHiddenOnMobile) {
            return null;
          }

          const isCustomLink = tab.id === 'customLink';

          const customLinkIcon =
            isCustomLink && tab?.icon?.length ? (
              <Image alt="" height={23.8} src={tab.icon} width={23.8} />
            ) : null;

          return (
            <Link
              key={index.toString()}
              className={cn(svgSizeStyles(2.8), 'px-16 py-8')}
              to={tab.to}>
              <Stack
                alignItems="center"
                className="h-48"
                direction="col"
                gapY={0}
                justifyContent="between">
                {customLinkIcon || (
                  <tab.Icon className={isSelected ? 'text-icon-active' : 'text-icon-placeholder'} />
                )}
                <Typography
                  className="mt-2"
                  color={isSelected ? 'text-text-primary' : 'text-text-secondary'}
                  variant="body-short-04">
                  {label}
                </Typography>
              </Stack>
            </Link>
          );
        })}
    </Stack>
  );
};

export default React.memo(BottomNavigationTabs);
