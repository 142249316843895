import React from 'react';
import Script from 'next/script';

import useScreen from '@lyearn/core/hooks/useScreen';
import { Stack } from '@lyearn/ui';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';

import { useUserContext } from '../auth/hooks/useUser';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'langflow-chat': any;
    }
  }
}

type Props = {};
export const KnowledgeBase = ({}: Props) => {
  const { [FeatureFlags.EnableKnowledgeBase]: isKnowledgeBaseEnabled } = useFeatureFlags();
  const user = useUserContext();

  const { isMobile } = useScreen();

  if (isMobile || !isKnowledgeBaseEnabled || user.emailId !== 'dev@lyearn.com') {
    return null;
  }

  return (
    <>
      <Script src="https://cdn.jsdelivr.net/gh/logspace-ai/langflow-embedded-chat@v1.0.6/dist/build/static/js/bundle.min.js" />
      <Stack
        style={{
          position: 'fixed',
          bottom: '50px',
          right: '50px',
          zIndex: 1000,
        }}>
        <langflow-chat
          api_key="sk-dOM_A8lx2ARUFLGneh6pBnchav4Kxv6_n4AKhWO585A"
          bot_message_style={JSON.stringify({
            backgroundColor: '#333333',
            color: 'white',
            animation: 'typing 2s steps(40, end) infinite',
          })}
          chat_position="top-left"
          flow_id="6a92b2f7-8dc2-45bc-9e8e-59acd247d085"
          host_url="https://lf-api.dev.lyearn.com"
          input_container_style={JSON.stringify({
            fontSize: '16px',
          })}
          // output_type="text"
          input_style={JSON.stringify({
            fontSize: '16px',
          })}
          user_message_style={JSON.stringify({
            fontSize: '16px',
          })}></langflow-chat>
      </Stack>
    </>
  );
};
