import React, { useCallback, useEffect, useMemo } from 'react';

import downloadFile from '@lyearn/core/utils/network/downloadFile';
import { useTranslation } from '@lyearn/i18n';
import { AlertTriangle } from '@lyearn/icons';
import { LinkButton, useSnackbar } from '@lyearn/ui';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import { useJobByCreatorSubscription } from '@/modules/corporate/modules/bulk-import/graphql/subscription/generated/jobByCreator';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';
import { JobEntityStatusEnum, JobPurposeEnum, JobSubscriptionOperationEnum } from '@/types/schema';

import { modifyJobStatus } from '../../../course/views/CourseLearnersReport/utils/job.util';
import { getFailedJobSnackBarTitle, getSnackBarTitle } from './utils/getSnackBarTitle.util';

const ADMIN_JOB_PURPOSE = [
  JobPurposeEnum.LearnerExport,
  JobPurposeEnum.TagExport,
  JobPurposeEnum.GroupMemberExport,
  JobPurposeEnum.TaggedEntitiesExport,
  JobPurposeEnum.OfflineEventAttendanceExport,
  JobPurposeEnum.ChartExport,
  JobPurposeEnum.ReportExport,
  JobPurposeEnum.SurveyExport,
  JobPurposeEnum.UserSurveyResponseExport,
  JobPurposeEnum.QuizQuestionExport,
  JobPurposeEnum.BulkUnAssignLearnerPreprocess,
  JobPurposeEnum.ForceCompleteContent,
];

const EXCLUDE_ACTION_JOBS_PURPOSE = [JobPurposeEnum.MergeAccount];

export const LEARNER_JOB_PURPOSE = [JobPurposeEnum.ChartExport];

const AUTO_DOWNLOAD_JOB_PURPOSE = [JobPurposeEnum.QuizQuestionExport];

const EXCLUDE_DOWNLOAD_JOB_PURPOSE = [JobPurposeEnum.ReportExport, JobPurposeEnum.ChartExport];

const SUCCESSFUL_SNACKBAR_DURATION = 6000;

const useJobByCreator = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const isAdminRoute = useAdminRoute();

  const jobPurpose = useMemo(
    () => (isAdminRoute ? ADMIN_JOB_PURPOSE : LEARNER_JOB_PURPOSE),
    [isAdminRoute],
  );

  const { id } = useUserContext();
  const [{ data }] = useJobByCreatorSubscription({
    variables: {
      input: {
        createdById: id,
        purpose: jobPurpose,
        operations: [
          JobSubscriptionOperationEnum.JobCreated,
          JobSubscriptionOperationEnum.JobUpdated,
        ],
      },
    },
    pause: !id,
  });

  const job = useMemo(() => modifyJobStatus(data?.jobByCreator.job), [data?.jobByCreator.job]);

  const onDownload = useCallback(() => {
    const outputMediaUrl = job?.outputMediaUrls[0];
    if (outputMediaUrl?.url) {
      downloadFile(outputMediaUrl?.url);
    }
  }, [job?.outputMediaUrls]);

  useEffect(() => {
    if (!job) {
      return;
    }
    if (job.status === JobEntityStatusEnum.Completed) {
      if (job.purpose && EXCLUDE_DOWNLOAD_JOB_PURPOSE.includes(job.purpose)) {
        // DO NOTHING
      } else if (job.purpose && AUTO_DOWNLOAD_JOB_PURPOSE.includes(job.purpose)) {
        onDownload();
      } else {
        const showAction = !EXCLUDE_ACTION_JOBS_PURPOSE.includes(job.purpose as JobPurposeEnum);
        const action = showAction ? (
          <LinkButton className="flex-shrink-0 py-2 px-4" color={'inverse'} onClick={onDownload}>
            {t('Common:Actions.Download')}
          </LinkButton>
        ) : undefined;
        showSnackbar({
          message: t(getSnackBarTitle(job, t)),
          duration: SUCCESSFUL_SNACKBAR_DURATION,
          iconStatus: 'success',
          action,
        });
      }
    }

    if (job.status === JobEntityStatusEnum.Failed) {
      showSnackbar({
        message: t(getFailedJobSnackBarTitle(job?.purpose)),
        startIcon: <AlertTriangle className="h-20 w-20" fillColorToken="icon-error-light" />,
      });
    }

    if (
      job.status === JobEntityStatusEnum.Cancelled &&
      job.purpose == JobPurposeEnum.BulkUnAssignLearnerPreprocess
    ) {
      showSnackbar({
        message: t('Common:BulkUnAssign.UnassigningLearnersWasCancelled'),
        startIcon: <AlertTriangle className="h-20 w-20" fillColorToken="icon-error-light" />,
      });
    }

    if (job.purpose === JobPurposeEnum.ForceCompleteContent) {
      switch (job.status) {
        case JobEntityStatusEnum.Completed:
          showSnackbar({
            message: t('Common:BulkForceComplete.Completed', {
              count: job?.input?.forceCompleteContentInput?.userIds.length || 0,
            }),
            duration: SUCCESSFUL_SNACKBAR_DURATION,
            iconStatus: 'success',
          });
          break;

        case JobEntityStatusEnum.Cancelled:
          showSnackbar({
            message: t('Common:BulkForceComplete.Cancelled'),
            appearance: 'error',
            startIcon: <AlertTriangle className="h-20 w-20" fillColorToken="icon-white" />,
          });
          break;

        case JobEntityStatusEnum.Failed:
          showSnackbar({
            message: t('Common:BulkForceComplete.Failed'),
            appearance: 'error',
            startIcon: <AlertTriangle className="h-20 w-20" fillColorToken="icon-white" />,
          });
          break;
      }
    }
  }, [showSnackbar, t, data?.jobByCreator.job, job, onDownload]);
};

export default useJobByCreator;
