import { SessionTabs } from '@/modules/events/views/EventManager/types';
import { TeamProfilePages } from '@/modules/user/views/TeamProfile/types';
import { EmployeeProfilePages } from '@/modules/user/views/UserProfile/types';

import { adminRoutes as okrAdminRoutes } from './okr';

export const PARENT_ROUTE = '/admin';

const routes = {
  Admin: () => PARENT_ROUTE,

  Dashboard: () => `${PARENT_ROUTE}/dashboard`,

  ViewAcademy: () => `${PARENT_ROUTE}/view-academy`,

  LearningContent: () => routes.LearningContentActive(),

  RecentLearningContent: () => `${PARENT_ROUTE}/my-recents`,

  LearningContentDraft: () => `${PARENT_ROUTE}/learning-content/draft`,

  LearningContentActive: () => `${PARENT_ROUTE}/learning-content/active`,

  LearningContentArchived: () => `${PARENT_ROUTE}/learning-content/archived`,

  ContentCreationLyearnAI: () => `${PARENT_ROUTE}/lyearn-ai/content/create`,

  AdminEvents: () => routes.ManageUpcomingEvents(),

  ManageUpcomingEvents: () => `${PARENT_ROUTE}/sessions/${SessionTabs.Upcoming}`,

  ManageFinishedEvents: () => `${PARENT_ROUTE}/sessions/${SessionTabs.Finished}`,

  ManageRecurringEvents: () => `${PARENT_ROUTE}/sessions/${SessionTabs.Recurring}`,

  Learners: () => routes.ActiveLearners(),

  ActiveLearners: () => `${PARENT_ROUTE}/learners/active`,

  PendingLearners: () => `${PARENT_ROUTE}/learners/pending`,

  DeactivatedLearners: () => `${PARENT_ROUTE}/learners/deactivated`,

  AdminLearnerProfile: (learnerId: string) => `${PARENT_ROUTE}/learners/${learnerId}`,

  Organization: () => `${PARENT_ROUTE}/organization`,

  Assignments: () => `${PARENT_ROUTE}/assignments`,

  SkillsAndMastery: () => `${PARENT_ROUTE}/skills-mastery`,

  AdminCertificates: () => `${PARENT_ROUTE}/certificates`,

  NotificationsManager: () => `${PARENT_ROUTE}/notifications`,

  CategoriesManager: () => `${PARENT_ROUTE}/categories`,

  SystemAgendaManager: () => `${PARENT_ROUTE}/system-agendas`,

  TaskStatusesManager: () => `${PARENT_ROUTE}/tasks/status`,

  Staff: () => routes.ActiveStaff(),

  ActiveStaff: () => `${PARENT_ROUTE}/staff/active`,

  PendingStaff: () => `${PARENT_ROUTE}/staff/pending`,

  DeactivatedStaff: () => `${PARENT_ROUTE}/staff/deactivated`,

  StaffRoles: () => `${PARENT_ROUTE}/staff-roles`,

  AdminToReview: () => `${PARENT_ROUTE}/to-review`,

  Reports: () => routes.StandardReports(),
  StandardReports: () => `${PARENT_ROUTE}/reports/standard`,
  CustomReports: () => `${PARENT_ROUTE}/reports/custom`,

  ReportsEditor: (reportId: string) => `${PARENT_ROUTE}/reports/${reportId}/edit`,

  Settings: (showBillingUsage?: boolean) =>
    showBillingUsage ? `${PARENT_ROUTE}/settings#showBillingUsage` : `${PARENT_ROUTE}/settings`,

  HomepageEditor: () => `${PARENT_ROUTE}/home/editor`,

  AcademySettings: () => `${PARENT_ROUTE}/academy-settings`,

  AuthenticationSettings: () => `${PARENT_ROUTE}/authentication-settings`,

  CommunityEditor: (communityId: string) => `${PARENT_ROUTE}/community/${communityId}/editor`,

  AcademiesManagement: () => `${PARENT_ROUTE}/academies-management`,

  MindsetManager: () => `${PARENT_ROUTE}/mindsets`,

  ...okrAdminRoutes,

  GettingStarted: () => `${PARENT_ROUTE}/getting-started`,
  Marketplace: (categoryId?: string) =>
    categoryId ? `${PARENT_ROUTE}/marketplace/${categoryId}` : `${PARENT_ROUTE}/marketplace`,

  MarketplaceSearch: () => `${PARENT_ROUTE}/marketplace/search`,

  MarketplaceWidget: (widgetId: string) => `${PARENT_ROUTE}/marketplace/widget/${widgetId}`,

  ProviderDetails: (providerId: string) => `${PARENT_ROUTE}/marketplace/provider/${providerId}`,

  // User Profile
  AdminUserProfile: (id: string, type = EmployeeProfilePages.Overview) =>
    `${PARENT_ROUTE}/user-profile/${id}/${type}`,
  AdminUserActivityDetails: (userId: string, metricId: string) =>
    `${PARENT_ROUTE}/user-profile/${userId}/${EmployeeProfilePages.Activities}/${metricId}`,
  AdminUserProfileSkillDetailPage: (userId: string, skillId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Skills}/${skillId}`,
  AdminLearnerActivityDetails: (userId: string, metricId: string) =>
    `${PARENT_ROUTE}/learners/${userId}/${EmployeeProfilePages.Activities}/${metricId}`,
  AdminUserProfileCompetencesPage: (userId: string) =>
    `${PARENT_ROUTE}/user-profile/${userId}/competences`,
  AdminUserProfileMindsetDetailPage: (userId: string, mindsetId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Mindsets}/${mindsetId}`,

  // Team Profile
  AdminTeamProfile: (id: string, type = TeamProfilePages.Overview) =>
    `/admin/team-profile/${id}/${type}`,

  // Question Bank
  AdminQuestionManager: (tab: string) => `${PARENT_ROUTE}/question-bank/${tab}`,

  AuditLog: () => `${PARENT_ROUTE}/audit-log`,

  //manage-subscription
  ManageSubscription: () => `${PARENT_ROUTE}/manage-subscription`,
};

export const defaultRoute = () => ({
  admin: routes.Admin(),
  learner: '/',
});

export const ADMIN_ROUTE_KEYS = {
  Admin: 'Admin',
  LearningContent: 'LearningContent',
  RecentLearningContent: 'RecentLearningContent',
  LearningContentDraft: 'LearningContentDraft',
  Dashboard: 'Dashboard',
  ViewAcademy: 'ViewAcademy',
  Learners: 'Learners',
  Organization: 'Organization',
  Assignments: 'Assignments',
  SkillsAndMastery: 'SkillsAndMastery',
  AdminCertificates: 'AdminCertificates',
  Staff: 'Staff',
  Reports: 'Reports',
  StandardReports: 'StandardReports',
  CustomReports: 'CustomReports',
  Settings: 'Settings',
  HomepageEditor: 'HomepageEditor',
  CommunityEditor: 'CommunityEditor',
  AcademySettings: 'AcademySettings',
  AuthenticationSettings: 'AuthenticationSettings',
  GettingStarted: 'GettingStarted',
  StaffRoles: 'StaffRoles',
};

export default routes;
