import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import { useUserContext } from '@/modules/auth/hooks/useUser';

import { useSubscriptionConfigQuery } from './graphql/query/generated/susbcriptionConfig';

function useSubscriptionConfig() {
  const user = useUserContext();
  const { [FeatureFlags.EnableSubscriptionManagement]: isSubscriptionManagementEnabled } =
    useFeatureFlags();

  const [{ data }] = useSubscriptionConfigQuery({ pause: !isSubscriptionManagementEnabled });
  const { id, subscriptionConfig } = data?.currentWorkspace || {};

  return {
    workspaceId: id,
    userId: user.id,
    customerId: subscriptionConfig?.customerId,
    endDate: subscriptionConfig?.endDate,
    type: subscriptionConfig?.type,
  };
}

export default useSubscriptionConfig;
