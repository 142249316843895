import React, { useMemo } from 'react';

import { Trans, useTranslation } from '@lyearn/i18n';
import { InfoOutline } from '@lyearn/icons';
import { LinkButton, Stack, Typography } from '@lyearn/ui';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import useSubscriptionConfig from '@/hooks/useSubscriptionConfig';
import routes from '@/pages/corporate/routes/admin';
import { SubscriptionTypeEnum } from '@/types/schema';

function FreeTrialBanner() {
  const { t } = useTranslation(['Common', 'Subscription']);
  const { userId, workspaceId, endDate, type: subscriptionType } = useSubscriptionConfig();
  const { [FeatureFlags.EnableSubscriptionManagement]: isSubscriptionManagementEnabled } =
    useFeatureFlags();

  const { hideBanner, messageKey, formattedEndDate } = useMemo(() => {
    if (!userId || !workspaceId || !isSubscriptionManagementEnabled) {
      return { hideBanner: true, messageKey: '', formattedEndDate: '' };
    }

    const date = endDate ? new Date(endDate) : null;
    const isEndDateElapsed = date ? date < new Date() : false;
    const formattedEndDate = date?.toLocaleDateString();

    const isFreeTrial = subscriptionType === SubscriptionTypeEnum.FreeTrail;
    const hasFreeTrialEnded = isFreeTrial && isEndDateElapsed;

    const isSubscription = subscriptionType === SubscriptionTypeEnum.Paid;
    const hasSubscriptionEnded = isSubscription && isEndDateElapsed;

    const hideBanner = (isSubscription && !hasSubscriptionEnded) || !formattedEndDate;

    let messageKey = 'Subscription:ExpiryBannerWithoutHighlight';
    if (isFreeTrial && hasFreeTrialEnded) {
      messageKey = 'Subscription:ExpiredBannerWithoutHighlight';
    } else if (isSubscription && hasSubscriptionEnded) {
      messageKey = 'Subscription:SubscriptionExpiredBanner';
    }

    return { hideBanner, messageKey, formattedEndDate };
  }, [userId, workspaceId, endDate, subscriptionType, isSubscriptionManagementEnabled]);

  if (hideBanner) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      className="bg-bg-warning w-full px-16 py-8"
      gapX={8}
      justifyContent="center">
      <InfoOutline className="h-18 w-18" fillColorToken="icon-active" />
      <Typography variant="body-short-02">
        <Trans i18nKey={messageKey} values={{ date: formattedEndDate }} />
      </Typography>
      <LinkButton className="text-text-primary underline" href={routes.ManageSubscription()}>
        {t('Subscription:UpgradeNow')}
      </LinkButton>
    </Stack>
  );
}

export default FreeTrialBanner;
