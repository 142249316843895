import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';

import { Skeleton, Stack } from '@lyearn/ui';
import { NavConfigFragment } from '@/components/ConfigsProvider/graphql/fragment/generated/NavConfig';

const DefaultRightNodeDynamic = dynamic(
  () => import(/* webpackPreload: true */ /* webpackChunkName: "header-default-right-node" */ './'),
);

const Loader = (
  <Stack className="space-s-12">
    <Skeleton
      animation="wave"
      className="absolute top-14 flex -translate-x-1/2 transform rounded-4 opacity-60 start-1/2 rtl:translate-x-1/2 rtl:translate-x-1/2 xs:hidden"
      height={32}
      variant="rectangular"
      width="35%"
    />
    {[1, 2, 3].map((num) => (
      <Skeleton
        key={num}
        animation="wave"
        className="rounded-4 opacity-60"
        height={32}
        variant="rectangular"
        width={32}
      />
    ))}
    <Skeleton animation="wave" className="opacity-60" height={32} variant="circular" width={32} />
  </Stack>
);

const DefaultRightNodeLazy = ({
  previewNavConfig,
}: {
  previewNavConfig?: NavConfigFragment | undefined;
}) => {
  return (
    <Suspense fallback={Loader}>
      <DefaultRightNodeDynamic previewNavConfig={previewNavConfig} />
    </Suspense>
  );
};

export default DefaultRightNodeLazy;
