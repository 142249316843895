import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { AnyVariables, stringifyVariables } from '@urql/core';
import filter from 'lodash/filter';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Object } from 'ts-toolbelt';

import { PageInfo } from '@/types/schema';
import * as Urql from '@/utils/urql';

export interface Params<T> {
  /**
   * key for pagination page
   */
  edgeKey?: T;
  /**
   *  number of documents to fetching
   */
  pageSize?: number;
  requestPolicy?: Urql.UseQueryArgs['requestPolicy'];
  pause?: Urql.UseQueryArgs['pause'];
  context?: Urql.UseQueryArgs['context'];
}

enum NetworkStatus {
  READY = 'READY', // Idle State
  LOADING = 'LOADING', // Initial call to API
  FETCH_MORE = 'FETCH_MORE', // Fetching next page
  REFETCH = 'REFETCH', // Refetch last call
  SET_VARIABLES = 'SET_VARIABLES', // Filter, search or sort params changed
}

export type useQueryType<Query extends object, Variables extends AnyVariables = AnyVariables> = (
  options: Omit<Urql.UseQueryArgs<Variables>, 'query'>,
) => Urql.UseQueryResponse<Query, object>;

const DEFAULT_EDGE_KEY = 'items' as const;

const EMPTY_ARRAY: never[] = [];

function usePagination<
  Query extends object,
  T extends string,
  Variables extends AnyVariables = AnyVariables,
>(
  useQuery: useQueryType<Query, Variables>,
  variables: Variables,
  { edgeKey, pageSize = 25, ...params }: Params<T> = {},
) {
  const [after, setAfter] = useState<string>();
  const endCursor = useRef('');

  // @ts-ignore
  const [result, _refetch] = useQuery({
    variables: { ...variables, first: pageSize, after },
    ...params,
  });
  const { fetching, data, error, stale, operation } = result;

  const [networkStatus, setNetworkStatus] = useState(
    fetching ? NetworkStatus.LOADING : NetworkStatus.READY,
  );

  const inputVariables = useMemo(() => stringifyVariables(variables), [variables]);
  // @ts-ignore
  const pageInfo = get(data, edgeKey ?? DEFAULT_EDGE_KEY)?.pageInfo as PageInfo | undefined;

  // will reset | restore the pagination cursor if query variables are changed
  // useUpdateEffect is used as query variables changed should not run on mount.
  useUpdateEffect(() => {
    if (!fetching && pageInfo) {
      endCursor.current = pageInfo.endCursor ?? '';
    } else {
      endCursor.current = '';
      setAfter(undefined);
      setNetworkStatus(NetworkStatus.SET_VARIABLES);
    }
  }, [inputVariables, params.pause]);

  // store the endCursor from the currentResult to get next page when fetchMore is called.
  useEffect(() => {
    if (!fetching && !error) {
      endCursor.current = pageInfo?.endCursor ?? '';
    }
  }, [fetching, data, error, pageInfo]);

  const fetchMore = useCallback(() => {
    setNetworkStatus(NetworkStatus.FETCH_MORE);
    setAfter(endCursor.current);
  }, [endCursor]);

  const refetch = useCallback(
    (opts?: any) => {
      setNetworkStatus(NetworkStatus.REFETCH);

      return _refetch({ requestPolicy: 'network-only', ...opts });
    },
    [_refetch],
  );

  useEffect(() => {
    if (!fetching && !stale) {
      setNetworkStatus(NetworkStatus.READY);
    }
  }, [fetching, networkStatus, stale]);

  const adaptedData = useMemo((): Object.Path<
    NonNullable<Object.Path<NonNullable<typeof data>, [NonNullable<typeof edgeKey>, 'edges', 0]>>,
    ['node']
  >[] => {
    const edgesPath = `${edgeKey ?? DEFAULT_EDGE_KEY}.edges`;
    const edges = get(data, edgesPath);

    if (!edges || isEmpty(edges)) {
      return EMPTY_ARRAY;
    }

    return map(filter(edges, identity), ({ node }) => node);
  }, [data, edgeKey]);

  const totalCount = get(data, `${edgeKey ?? DEFAULT_EDGE_KEY}.totalCount`);

  return {
    data: adaptedData,
    error,
    operation,
    refetch,
    result,

    hasNextPage: Boolean(pageInfo?.hasNextPage),
    fetchMore: pageInfo?.hasNextPage ? fetchMore : undefined,
    /**
     * will be true on variable change or network loading
     */
    isFetching: [NetworkStatus.LOADING, NetworkStatus.SET_VARIABLES].includes(networkStatus),
    isFetchingMore: networkStatus === NetworkStatus.FETCH_MORE,
    /**
     * will be true when network call is made
     */
    isLoading: networkStatus === NetworkStatus.LOADING,
    isRefetching: networkStatus === NetworkStatus.REFETCH,
    totalCount,
    setAfter,
  };
}

export default usePagination;
