import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import _find from 'lodash/find';
import _isMatch from 'lodash/isMatch';
import isNil from 'lodash/isNil';
import _keyBy from 'lodash/keyBy';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  ActionItemPriorityEnum,
  ActionItemSubscriptionOperationEnum,
  ActionItemSubscriptionPayload,
} from '@/types/schema';

import {
  PaginatedGroupedActionItemsDocument,
  PaginatedGroupedActionItemsQuery,
  PaginatedGroupedActionItemsQueryVariables,
} from '../../../../../graphql/queries/generated/paginatedGroupedActionItems';
import { isGroupedByTag } from '../../../../../views/TasksDashboard/components/TaskGroupByFilter/utils';
import getPaginationFilters from '../../../../../views/TasksDashboard/hooks/useGroupedActionItems/getPaginationFilters';
import { ActionItemFragment } from '../../fragments/generated/ActionItem';
import { UpdateActionItemMutation } from '../../mutations/generated/updateActionItem';
import {
  ActionItemsDocument,
  ActionItemsQuery,
  ActionItemsQueryVariables,
} from '../../queries/generated/actionItems';

const newBlankGroup = () => ({
  groupByKey: '',
  groupedEntity: {
    totalCount: 0,
    edges: [],
    __typename: 'PaginatedActionItemsPayload' as 'PaginatedActionItemsPayload',
  },
  __typename: 'GroupedActionItemEdge' as 'GroupedActionItemEdge',
});

const QUERY_NAME = 'actionItems';
const getGroupOrder: Record<string, Record<string, number>> = {
  status: { todo: 0, inProgress: 1, inReview: 2, completed: 3 },
  priority: { urgent: 0, high: 1, medium: 2, low: 3, noPriority: 4 },
  dueDate: { Overdue: 0, Today: 1, 'This week': 2, Future: 3, Done: 4, 'No Due Date': 5 },
};

const findIfExist = <T>(array?: T[] | null, item?: T) => {
  if (array?.length && item) {
    return array.includes(item);
  }
};

const getSelectedGroup = (actionItem: ActionItemFragment, groupName: keyof ActionItemFragment) => {
  const value = actionItem[groupName] as string;
  if (groupName === 'priority') {
    return value.toLowerCase();
  }

  if (groupName === 'dueDate') {
    if (actionItem['status'] === 'completed') return 'Done';
    if (!value) {
      return 'No Due Date';
    }
    const valueDate = new Date(value);
    if (new Date() > valueDate) {
      return 'Overdue';
    }
    if (isToday(valueDate)) {
      return 'Today';
    }
    if (isThisWeek(valueDate)) {
      return 'This week';
    }

    return 'Future';
  }

  if (isGroupedByTag(groupName)) {
    const selectedTag = _find(actionItem.tags, { tagId: groupName.split(':')[1] });

    return selectedTag?.optionIdValues?.[0] || selectedTag?.users?.[0]?.id;
  }

  return value;
};

function handleOrderChange(cache: Cache, actionItem?: ActionItemFragment | null) {
  if (!actionItem) {
    return;
  }
  const allActionItemsEdges: any[] = [];
  inspectCache(cache, 'Query', 'actionItems', (field) => {
    const args = field.arguments as ActionItemsQueryVariables;
    if (!args.first) {
      return;
    }

    const actionItemQueryData = cache.readQuery({
      query: ActionItemsDocument,
      variables: args,
    }) as ActionItemsQuery;
    if (actionItemQueryData && actionItemQueryData?.actionItems?.edges?.length) {
      const edges = actionItemQueryData?.actionItems?.edges;
      allActionItemsEdges.push(...edges);
    }
  });
  allActionItemsEdges.sort((a, b) => a?.node?.order - b?.node?.order);

  let currentIndex = 0;
  const totalCount = allActionItemsEdges.length ?? 0;
  inspectCache(cache, 'Query', 'actionItems', (field) => {
    const args = field.arguments as ActionItemsQueryVariables;
    if (!args.first) {
      return;
    }

    updateCache<ActionItemsQuery, ActionItemsQueryVariables>(
      cache,
      ActionItemsDocument,
      args,
      (actionItemQueryData) => {
        if (actionItemQueryData?.actionItems?.edges?.length) {
          const size = actionItemQueryData?.actionItems?.edges?.length ?? 25;
          const upparBoundIndex = Math.min(currentIndex + size, totalCount);
          const edges = allActionItemsEdges.slice(currentIndex, upparBoundIndex);
          actionItemQueryData.actionItems.edges = edges;
          currentIndex = upparBoundIndex;
        }
        return actionItemQueryData;
      },
    );
  });
}

function handleActionItemUpdate(cache: Cache, actionItem?: ActionItemFragment | null) {
  if (!actionItem || actionItem.parentId) {
    return;
  }

  inspectCache(cache, 'Query', 'paginatedGroupedActionItems', (field) => {
    const args = field.arguments as PaginatedGroupedActionItemsQueryVariables;
    if (args.groupBy) {
      const groupByKey = args.groupBy as keyof typeof actionItem;
      updateCache<PaginatedGroupedActionItemsQuery, PaginatedGroupedActionItemsQueryVariables>(
        cache,
        PaginatedGroupedActionItemsDocument,
        args,
        (data) => {
          /* check if actionItem is in the latest group if so we don't need to update it
            else we need add actionItem in the latest group and find and remove from old group remove
           */
          if (data?.paginatedGroupedActionItems?.edges) {
            const selectedGroup = getSelectedGroup(actionItem, groupByKey);
            if (typeof selectedGroup === 'undefined') {
              if (isGroupedByTag(groupByKey)) {
                data.paginatedGroupedActionItems.edges =
                  data?.paginatedGroupedActionItems?.edges.filter(({ node }) => {
                    if (node.groupedEntity?.edges?.length) {
                      node.groupedEntity.edges = node.groupedEntity.edges.filter(
                        ({ node }) =>
                          node?.tags?.find((tag) => groupByKey.indexOf(tag?.tagId!) !== -1),
                      );
                    }

                    return node.groupedEntity.edges?.length;
                  });
              }

              return data;
            }

            const groupOrder = getGroupOrder[groupByKey] || {};

            let { [selectedGroup]: expectedGroup, ...restGroups } = _keyBy(
              data?.paginatedGroupedActionItems?.edges.map(({ node }) => node),
              'groupByKey',
            );
            const taskIndex =
              expectedGroup?.groupedEntity.edges?.findIndex(
                (task) => task.node.id === actionItem.id,
              ) ?? -1;
            // if actionItem is not in correct group then add in the group and remove from other group
            if (taskIndex === -1) {
              // remove item from old group
              Object.values(restGroups).forEach((edge) => {
                if (edge?.groupedEntity.edges?.length) {
                  const previousSize = edge.groupedEntity.edges.length;
                  edge.groupedEntity.edges = edge.groupedEntity.edges.filter(
                    (item) => item.node.id !== actionItem.id,
                  );

                  // to update actionItems query which are fetch for each group in pagination
                  inspectCache(cache, 'Query', 'actionItems', (field) => {
                    const args = field.arguments as ActionItemsQueryVariables;
                    // not a pagination query
                    if (!args.first || !args.filters) {
                      return;
                    }
                    const paginationFilter = getPaginationFilters(
                      edge.groupByKey,
                      groupByKey,
                      actionItem,
                    );

                    if (_isMatch(args.filters, paginationFilter)) {
                      updateCache<ActionItemsQuery, ActionItemsQueryVariables>(
                        cache,
                        ActionItemsDocument,
                        args,
                        (actionItemQueryData) => {
                          if (actionItemQueryData?.actionItems?.edges?.length) {
                            actionItemQueryData.actionItems.edges =
                              actionItemQueryData.actionItems.edges.filter(
                                (item) => item.node.id !== actionItem.id,
                              );
                          }

                          return actionItemQueryData;
                        },
                      );
                    }
                  });

                  const itemsRemoved = previousSize - edge.groupedEntity.edges.length;
                  if (!isNil(edge.groupedEntity.totalCount) && itemsRemoved > 0) {
                    edge.groupedEntity.totalCount -= itemsRemoved;
                  }
                }
              });

              if (!expectedGroup) {
                expectedGroup = { ...newBlankGroup(), groupByKey: selectedGroup };
                data?.paginatedGroupedActionItems?.edges.push({
                  node: expectedGroup,
                  __typename: 'PaginatedGroupedActionItemEdge',
                });

                // sort the groups by order
                data?.paginatedGroupedActionItems?.edges.sort(
                  (edge1, edge2) =>
                    groupOrder[edge1.node.groupByKey] - groupOrder[edge2.node.groupByKey],
                );
              }
              // add item to expected group
              // todo need to add sort logic while adding new item
              expectedGroup.groupedEntity.edges?.unshift({
                node: actionItem,
                __typename: 'ActionItemEdge',
              });
              if (!isNil(expectedGroup.groupedEntity.totalCount)) {
                expectedGroup.groupedEntity.totalCount++;
              }
            }

            // remove actionItems with doesn't match filters
            data?.paginatedGroupedActionItems?.edges.map(({ node }) => {
              if (!node.groupedEntity.edges) {
                return node;
              }
              const previousSize = node.groupedEntity.edges.length;
              node.groupedEntity.edges = node.groupedEntity.edges.filter((item) => {
                const priorityMatch =
                  findIfExist(
                    args.filters?.priorities,
                    item.node.priority?.toUpperCase() as ActionItemPriorityEnum,
                  ) ?? true;
                const assignedToIdMatch =
                  findIfExist(args.filters?.assignedToIds, item.node.assignedToId) ?? true;

                const fileIdsMatch = findIfExist(args.filters?.fileIds, item.node.fileId) ?? true;

                const createdByMatch =
                  findIfExist(args.filters?.createdByIds, item.node.createdById) ?? true;

                let unCategoryMatch = true;
                // keep items without fileId for unCategory
                if (args.filters?.unCategorized === false) {
                  unCategoryMatch = !item.node.fileId;
                }

                return (
                  priorityMatch &&
                  assignedToIdMatch &&
                  fileIdsMatch &&
                  unCategoryMatch &&
                  createdByMatch
                );
              });

              const itemsRemoved = previousSize - node.groupedEntity.edges.length;
              if (!isNil(node.groupedEntity.totalCount) && itemsRemoved > 0) {
                node.groupedEntity.totalCount -= itemsRemoved;
              }

              return node;
            });
          }

          // remove empty groups
          if (data?.paginatedGroupedActionItems?.edges.length) {
            data.paginatedGroupedActionItems.edges =
              data?.paginatedGroupedActionItems?.edges.filter(
                (edges) => edges.node.groupedEntity.edges?.length,
              );
          }

          return data;
        },
      );
    }
  });
}

export function getActionItemUpdater(): UpdateResolver {
  return (result, args, cache) => {
    const actionItemsPayload = result.actionItems as ActionItemSubscriptionPayload;
    const operationType = actionItemsPayload.operation;
    const actionItem = actionItemsPayload.actionItem;

    switch (operationType) {
      case ActionItemSubscriptionOperationEnum.ActionItemUpdated: {
        handleActionItemUpdate(cache, actionItem as ActionItemFragment);
        break;
      }
      default:
        return;
    }
  };
}

export function updateActionItemMutation(): UpdateResolver {
  return (result, args, cache) => {
    const allQueries = cache.inspectFields('Query');
    const { data } = (args ?? {}) as any;
    const isOrderChanged = data?.order;
    if (isOrderChanged && result?.updateActionItem) {
      handleOrderChange(cache, result?.updateActionItem as ActionItemFragment);
    }
    const actionItem = (result as UpdateActionItemMutation)?.updateActionItem?.actionItem;
    handleActionItemUpdate(cache, actionItem);
  };
}
