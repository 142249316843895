import isUndefined from 'lodash/isUndefined';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { jsonParse } from '@lyearn/core/utils/json';
import { Home2Outline } from '@lyearn/icons';
import { NavConfigFragment } from '@/components/ConfigsProvider/graphql/fragment/generated/NavConfig';
import { Link } from '@/components/Link';
import { FeatureFlags, FeatureFlagsMap } from '@/helper/FeatureFlags';
import routes from '@/pages/corporate/routes';
import {
  NavbarElement,
  NavbarElementTypeEnum,
  NavbarMenuChild,
  PageComponentStatusEnum,
} from '@/types/schema';

import {
  MenuItemPathsConfigs,
  MOBILE_DEFAULT_ENABLED_TABS,
  MOBILE_ENABLED_TABS,
  MobileNavBarIconsMap,
  NavBarIconsMap,
} from './consts';
import {
  AdaptedLinkTab,
  AdaptedMenuTab,
  AdaptMenuTabsParams,
  MobileNavigationType,
  NavMenuItemProps,
} from './types';

const NAV_KEY_FEATURE_FLAG_MAP: Record<string, FeatureFlags> = {
  okrs: FeatureFlags.EnableOKR,
  meetings: FeatureFlags.EnableMeeting,
  '1on1': FeatureFlags.EnableOneOnOne,
  praise: FeatureFlags.EnablePraise,
  feedback: FeatureFlags.EnableFeedback,
  habits: FeatureFlags.EnableHabits,
  survey: FeatureFlags.EnableUserSurvey,
  'review-cycles': FeatureFlags.EnablePerformanceReviews,
  tasks: FeatureFlags.EnableOKR,
};

export const getAdaptedNavConfig = (
  navConfig?: NavConfigFragment,
  previewNavConfig?: NavConfigFragment,
) => {
  const currentNavConfig = previewNavConfig || navConfig;
  if (!currentNavConfig) {
    return undefined;
  }
  const adaptedChildren = currentNavConfig.children
    .filter((entity) => entity.status !== PageComponentStatusEnum.Hidden)
    .map((entity) => ({
      ...entity,
      children: (entity?.children || []).filter(
        (child) => child?.status === undefined || child?.status !== PageComponentStatusEnum.Hidden,
      ),
    }));
  return {
    ...currentNavConfig,
    children: adaptedChildren,
  };
};

const isItemEnabled = (key: string, featureFlags: FeatureFlagsMap) => {
  const featureFlagKey = NAV_KEY_FEATURE_FLAG_MAP[key];
  return (
    isUndefined(featureFlagKey) || // undefined when key is not controlled via flag
    featureFlags[featureFlagKey]
  );
};

const getLinks = (
  entity: NavbarElement,
  featureFlags: FeatureFlagsMap,
  isMobile?: boolean,
): AdaptedLinkTab | undefined => {
  const { key, label, props, icon } = entity;

  if (!isItemEnabled(key, featureFlags)) {
    return;
  }

  const properties = jsonParse(props ?? '{}').data as any;

  if (
    (properties?.hideLinkInHeader && !isMobile) ||
    (properties?.showInMobileView === false && isMobile)
  ) {
    return;
  }

  const getLinkObject = (defaultUrl?: string): AdaptedLinkTab => {
    return {
      type: NavbarElementTypeEnum.Link,
      id: key,
      label,
      value: defaultUrl ?? MenuItemPathsConfigs[key],
      to: defaultUrl ?? MenuItemPathsConfigs[key],
      icon: icon || '',
      Icon: icon ? NavBarIconsMap[icon] : NavBarIconsMap['LargeFlag'],
    };
  };

  switch (key) {
    case 'community': {
      const communityId = properties.communityId;

      if (!communityId) {
        ErrorReporter.error(new Error(`Community id is not defined for menu item ${key}`));
      }
      return getLinkObject(routes.CommunityDetails(communityId));
    }
    case 'library': {
      const defaultWidgetId = properties.defaultWidgetId;
      if (!defaultWidgetId) {
        ErrorReporter.error(new Error(`Default widget id is not defined for menu item ${key}`));
      }
      const url = routes.Discover(defaultWidgetId);
      return getLinkObject(url);
    }
    case 'business':
    case 'digital':
    case 'product': {
      const defaultWidgetId = properties.defaultWidgetId;
      if (!defaultWidgetId) {
        ErrorReporter.error(new Error(`Default widget id is not defined for menu item ${key}`));
      }
      const url = routes.WidgetDetails(defaultWidgetId);
      return getLinkObject(url);
    }
    case 'customLink': {
      const defaultWidgetId = properties.defaultWidgetId;
      if (!defaultWidgetId) {
        ErrorReporter.error(new Error(`Default widget id is not defined for menu item ${key}`));
      }
      const url = routes.WidgetDetails(defaultWidgetId);
      return getLinkObject(url);
    }
    default: {
      return getLinkObject();
    }
  }
};

const getReturnObject = (child: NavbarMenuChild, url?: string) => {
  const { key, icon, label, description } = child;

  return {
    id: key,
    label,
    description: description || '',
    StartIcon: icon ? NavBarIconsMap[icon] : NavBarIconsMap['LargeFlag'],
    as: Link,
    asProps: {
      to: url ?? MenuItemPathsConfigs[key],
    },
    icon: icon || '',
  };
};

const getMenuItem = (
  child: NavbarMenuChild,
  featureFlags: FeatureFlagsMap,
  isMobile?: boolean,
): NavMenuItemProps | undefined => {
  const id = child.key;

  if (!isItemEnabled(id, featureFlags)) {
    return;
  }

  const { props } = child;
  const properties = JSON.parse(props ?? '{}');

  if (
    (properties?.hideLinkInHeader && !isMobile) ||
    (properties?.showInMobileView === false && isMobile)
  ) {
    return;
  }

  switch (id) {
    case 'community': {
      const communityId = properties.communityId;

      if (!communityId) {
        ErrorReporter.error(new Error(`Community id is not defined for menu item ${id}`));
      }

      return getReturnObject(child, routes.CommunityDetails(communityId));
    }
    case 'library': {
      const defaultWidgetId = properties.defaultWidgetId;
      if (!defaultWidgetId) {
      }
      const url = routes.Discover(defaultWidgetId);
      return getReturnObject(child, url);
    }
    case 'customLink': {
      const defaultWidgetId = properties.defaultWidgetId;
      if (!defaultWidgetId) {
        ErrorReporter.error(new Error(`Default widget id is not defined for menu item ${id}`));
      }
      const url = routes.WidgetDetails(defaultWidgetId);
      return getReturnObject(child, url);
    }
    default: {
      return getReturnObject(child);
    }
  }
};

const getMenu = (
  entity: NavbarElement,
  featureFlags: FeatureFlagsMap,
): AdaptedMenuTab | undefined => {
  const { key, children, label } = entity;

  if (!children || children?.length === 0) {
    ErrorReporter.error({
      message: `Menu has no children: ${key}`,
      tags: {
        tabId: key,
      },
    });
    return;
  }

  const menuChildren = children
    .map((child) => getMenuItem(child, featureFlags))
    .filter((child) => child) as NavMenuItemProps[];

  return menuChildren.length
    ? {
        type: NavbarElementTypeEnum.Menu,
        id: key,
        label,
        children: menuChildren,
        Icon: Home2Outline,
        status: entity?.status,
      }
    : undefined;
};

export const getTab = (entity: NavbarElement, featureFlags: FeatureFlagsMap) => {
  const { type } = entity;

  switch (type) {
    case NavbarElementTypeEnum.Link: {
      return getLinks(entity, featureFlags, false);
    }
    case NavbarElementTypeEnum.Menu: {
      return getMenu(entity, featureFlags);
    }
    default:
      return;
  }
};

export const adaptMenuTabs = (params: AdaptMenuTabsParams) => {
  const { navConfig, featureFlags } = params;
  return navConfig?.children.map((entity) => getTab(entity, featureFlags));
};

export const adaptMobileNavigationTabs = (
  params: AdaptMenuTabsParams,
): (MobileNavigationType | undefined)[] | undefined => {
  const { navConfig, featureFlags } = params;

  const allLinkTypes = navConfig?.children?.filter(
    (entity) => entity.type === NavbarElementTypeEnum.Link,
  );
  const allMenuTypes = navConfig?.children.reduce((acc, entity) => {
    if (entity.type === NavbarElementTypeEnum.Menu && entity.children) {
      return [...acc, ...entity.children];
    }
    return acc;
  }, [] as NavbarMenuChild[]);

  const showInMobileViewLinks = [...(allLinkTypes ?? []), ...(allMenuTypes ?? [])]
    ?.filter((linkItem) => {
      const properties = jsonParse(linkItem?.props || '{}').data as any;
      return Boolean(properties.showInMobileView);
    })
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const enabledTabs = showInMobileViewLinks?.length
    ? [...MOBILE_DEFAULT_ENABLED_TABS, ...showInMobileViewLinks.map((linkItem) => linkItem.key)]
    : MOBILE_ENABLED_TABS;

  return enabledTabs.map((id) => {
    const linkEntity = allLinkTypes?.find((entity) => entity.key === id);
    const menuEntity = allMenuTypes?.find((entity) => entity.key === id);

    if (linkEntity) {
      const e = getLinks(linkEntity, featureFlags, true);
      return e
        ? {
            label: e.label,
            value: e.value,
            to: e.to,
            Icon: MobileNavBarIconsMap[id],
            id: e.id,
            order: linkEntity?.order || 0,
            props: linkEntity?.props || '{}',
            icon: e.icon,
          }
        : undefined;
    } else if (menuEntity) {
      const e = getMenuItem(menuEntity, featureFlags, true);
      return e
        ? {
            label: e.label,
            value: e.asProps.to,
            to: e.asProps.to,
            // we are not using server driven icons for mobile because `Large` type icons appear too small in mobile
            Icon: MobileNavBarIconsMap[id],
            id: e.id,
            order: menuEntity?.order || 0,
            props: menuEntity?.props || '{}',
          }
        : undefined;
    }
    return;
  });
};
