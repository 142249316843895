import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import { cn } from '@lyearn/style';
import { Box, Stack } from '@lyearn/ui';
import Breadcrumbs from '@/components/Breadcrumb/components/Breadcrumbs';
import useScrollProgress from '@/hooks/useScrollProgress';

import DefaultLeftNode from '../components/DefaultLeftNode';
import DefaultRightNodeLazy from '../components/DefaultRightNode/index.lazy';
import { DefaultHeaderProps } from '../types';
import { headerOnBg } from './style';

const DefaultHeader = React.memo(
  (
    props: DefaultHeaderProps & {
      animate: boolean;
    },
  ) => {
    const {
      LeftNode,
      RightNode,
      className,
      showNavigationTabs,
      CenterNode,
      SubHeader,
      logoPreviewUrl,
      animate,
      isDisabled,
      showBreadcrumbs,
      previewNavConfig,
    } = props;

    const HeaderLeftNode =
      showNavigationTabs || LeftNode === undefined ? (
        <DefaultLeftNode logoPreviewUrl={logoPreviewUrl} textColor="text-text-primary" />
      ) : (
        LeftNode
      );

    const HeaderRightNode =
      showNavigationTabs || RightNode === undefined ? (
        <DefaultRightNodeLazy previewNavConfig={previewNavConfig} />
      ) : (
        RightNode
      );
    const { isLandScapeMode } = useScreen();
    const height = isLandScapeMode ? 'h-[52px]' : 'h-[56px]';
    const SubHeaderNode = showNavigationTabs || SubHeader === undefined ? null : SubHeader;

    return (
      <Stack
        className={cn(
          'sticky top-0 z-50',
          animate ? 'animate' : '',
          headerOnBg(showNavigationTabs),
          isDisabled ? 'pointer-events-none' : '',
        )}
        component="header"
        direction="col">
        <Box
          className={cn(
            'relative flex flex-shrink-0 items-center border-divider bg-bg-primary px-8 py-10 text-text-primary',
            className,
            height,
            { 'border-b': !showNavigationTabs },
          )}>
          <Box className="flex flex-grow flex-row items-center">{HeaderLeftNode}</Box>
          <Box>{CenterNode}</Box>
          <Box className="flex flex-grow flex-row justify-end me-12">{HeaderRightNode}</Box>
        </Box>
        {SubHeaderNode}
        {showBreadcrumbs ? <Breadcrumbs className={animate ? undefined : 'border-b'} /> : null}
      </Stack>
    );
  },
);

function DefaultHeaderWrapper(props: DefaultHeaderProps) {
  const { scrollProgress } = useScrollProgress(props.scrollContainerEl);
  const animate = !!scrollProgress;

  return <DefaultHeader animate={animate} {...props} />;
}

export default DefaultHeaderWrapper;
