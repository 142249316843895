import {
  Article,
  BookmarkOutline,
  BookOutline,
  CheckmarkFill,
  CheckmarkSquare,
  ClockOutline,
  Edit2Outline,
  FireOutline,
  Flag2Outline,
  FolderOutline,
  GridOutline,
  SurveyOutline,
  TvOutline,
  Ufo,
} from '@lyearn/icons';

import { ResourceTypeEnum } from '../../../types/schema';
import { BreadcrumbModuleType } from '../context';
import { BreadcrumbModuleEnum } from '../hooks/useBreadcrumb';

/**
 * As breadcrumbs data is serialized, we need to provide a mapping between
 * resource types and their corresponding icons.
 */
export const BREADCRUMBS_RESOURCE_TYPE_CONFIG: Partial<
  Record<NonNullable<BreadcrumbModuleType['type']>, { Icon: typeof BookOutline }>
> = {
  [ResourceTypeEnum.Course]: {
    Icon: BookOutline,
  },
  [ResourceTypeEnum.Path]: {
    Icon: Flag2Outline,
  },
  [ResourceTypeEnum.Article]: {
    Icon: Article,
  },
  [ResourceTypeEnum.Exercise]: {
    Icon: Edit2Outline,
  },
  // [ResourceTypeEnum.Task]: {
  //   Icon: DoneAll,
  // },
  [ResourceTypeEnum.Quiz]: {
    Icon: CheckmarkSquare,
  },
  [ResourceTypeEnum.ExternalContent]: {
    Icon: Ufo,
  },
  [ResourceTypeEnum.Scorm]: {
    Icon: FolderOutline,
  },
  [ResourceTypeEnum.Survey]: {
    Icon: SurveyOutline,
  },
  [BreadcrumbModuleEnum.AllSession]: {
    Icon: TvOutline,
  },
  [BreadcrumbModuleEnum.MySession]: {
    Icon: CheckmarkFill,
  },
  [BreadcrumbModuleEnum.Bookmark]: {
    Icon: BookmarkOutline,
  },
  ['LIBRARY']: {
    Icon: GridOutline,
  },
  ['ALL_CONTENT']: {
    Icon: GridOutline,
  },
  ['MOST_POPULAR']: {
    Icon: FireOutline,
  },
  ['NEWLY_ADDED']: {
    Icon: ClockOutline,
  },
};
